import { useEffect, useState } from 'react';
import { ILinkFarmTab } from '../../../api/types/content-api';
import { trackEDDL } from '../../../utilities/analytics';

const LinkFarmTab = (props: any) => {
    const { tabName, tabResponsiveName }: ILinkFarmTab = props.tab;
    const { activeTab, tabIndex, setActiveTab } = props;
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(activeTab === tabIndex);
    }, [activeTab]);

    return (
        <button
            type="button"
            className={`hp-tabs-header ${isActive && 'hp-tabs-header--active'}`}
            role="tab"
            aria-selected={isActive}
            tabIndex={isActive ? 0 : -1}
            onClick={() => {
                // Analytics
                trackEDDL({
                    event: 'ctaClick',
                    linkDetails: {
                        name: tabName,
                        position: 'Browse By',
                    },
                });

                // Change tab
                setActiveTab(tabIndex);
            }}
            data-kmx-analytics={`prop5=${tabName}`}
        >
            <span className="hp-tabs-header-label-container">
                <span className="hp-tabs-header-label">
                    <span className="link-farm-tabs--button-label">{tabName}</span>
                    <span className="link-farm-tabs--button-responsive-label">{tabResponsiveName}</span>
                </span>
            </span>
            <span className="hp-tabs-header-indicator"></span>
        </button>
    );
};

export default LinkFarmTab;
