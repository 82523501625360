import * as React from 'react';
import Carousel from './Carousel';
import * as analytics from '../../../utilities/analytics';
import ResearchArticlesTile from './ResearchArticlesTile';
import { getRelatedContent } from '../../../api/research-service-api';
import { RelatedContentItem } from '../../../api/types/research-related-content-response';
import ResearchArticlesPrompt, { IResearchPrompt, ResearchTile } from './ResearchArticlesPrompt';
import { ITrackingPayload } from '../../../types/analytics';
import { ANALYTICS_CONSTANTS } from '../../../utilities/analytics';

interface IResearchArticlesContainerProps {
    fullStoryPageId: string;
    nextFocus: string;
}

interface IResearchArticlesContainerState {
    researchRelatedContentItems: (RelatedContentItem | IResearchPrompt)[];
}

const researchPromptData: IResearchPrompt = {
    type: ResearchTile.Prompt,
    heading: 'Want to learn more about our best-sellers?',
    buttonLabel: 'view all research',
};

class ResearchArticlesContainer extends React.Component<
    IResearchArticlesContainerProps,
    IResearchArticlesContainerState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            researchRelatedContentItems: [],
        };
    }

    public componentDidMount(): void {
        this.loadRelatedContentData();
    }

    public render(): React.ReactNode {
        if (this.state.researchRelatedContentItems.length < 1) {
            return <></>;
        }
        return (
            <Carousel
                name="research-articles"
                nextFocus={this.props.nextFocus}
                onIndexChanged={() => {
                    analytics.track('Research & Articles Slide View');
                    const name = 'Research & Articles Slide View';
                    const event = ANALYTICS_CONSTANTS.EVENTS.click;
                    const payload: ITrackingPayload = {
                        event: event,
                        linkDetails: {
                            name: name,
                        },
                    };
                    analytics.trackEDDL(payload);
                }}
            >
                {(this.state.researchRelatedContentItems as (RelatedContentItem | IResearchPrompt)[]).map(
                    (tileData: RelatedContentItem | IResearchPrompt, index: number) => {
                        if ((tileData as IResearchPrompt)?.type === ResearchTile.Prompt) {
                            return (
                                <ResearchArticlesPrompt
                                    content={researchPromptData as IResearchPrompt}
                                    key={index}
                                    itemIndex={index}
                                />
                            );
                        }
                        return (
                            <ResearchArticlesTile
                                content={tileData as RelatedContentItem}
                                key={index}
                                itemIndex={index}
                            />
                        );
                    }
                )}
            </Carousel>
        );
    }

    private loadRelatedContentData = (): void => {
        getRelatedContent().then((response) => {
            let items: (RelatedContentItem | IResearchPrompt)[] = response?.data[0]?.content;
            items = items.filter((item) => (item as RelatedContentItem)?.image?.url);
            items.push(researchPromptData);
            this.setState({ researchRelatedContentItems: items });
        });
    };
}

export default ResearchArticlesContainer;
