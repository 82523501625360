import * as React from 'react';
import { HeroTheme } from '../../utilities/constants';
import * as analytics from '../../../../utilities/analytics';
import HeroFeaturedContent from '../HeroFeaturedContent';
import { ITrackingPayload } from '../../../../types/analytics';

interface IFirstTimeHeroProps {
    fullStoryPageId: string;
}

/*
        NOTE: Changes to this component must be duplicated in _FirstTimeHero.cshtml
*/

export default class FirstTimeHero extends React.Component<IFirstTimeHeroProps> {
    public componentDidMount(): void {
        setTimeout(function () {
            const pageInfo = window.digitalData.page.pageInfo;
            const userInfo = window.digitalData.user[0].attributes;
            const pageLoadPayLoad: ITrackingPayload = {
                event: 'pageLoad',
                pageInfo: {
                    pageName: pageInfo.pageName,
                    pageID: pageInfo.pageID,
                    originPage: pageInfo.originPage,
                    referringURL: pageInfo.referringURL,
                    subsection: pageInfo.subsection,
                    channel: pageInfo.channel,
                },
                userInfo: {
                    akamaiCity: userInfo.akamaiCity,
                    akamaiLatitude: userInfo.akamaiLatitude,
                    akamaiLongitude: userInfo.akamaiLongitude,
                    akamaiZip: userInfo.akamaiZip,
                    akamiRegionCode: userInfo.akamiRegionCode,
                    akamiTimeZone: userInfo.akamiTimeZone,
                    distanceToStore: userInfo.distanceToStore,
                    isFirstTimeVisitor: userInfo.isFirstTimeVisitor,
                    nearestStoreId: userInfo.nearestStoreId,
                    nearestStoreName: userInfo.nearestStoreName,
                    nearestStoreZip: userInfo.nearestStoreZip,
                    userZip: userInfo.userZip,
                    usingStoreProxy: userInfo.usingStoreProxy,
                    visitorID: userInfo.visitorID,
                    testSegment: {
                        logOdds: userInfo.testSegment.logOdds,
                        logOddsA: userInfo.testSegment.logOddsA,
                        logOddsI: userInfo.testSegment.logOddsI,
                        modelDecile: userInfo.testSegment.modelDecile,
                    },
                },
                profile: {
                    profileID: window.digitalData.user[0].profile[0].profileID,
                },
            };
            analytics.trackEDDL(pageLoadPayLoad);
            analytics.track('HP Brand Hero Init', true);
            const payload: ITrackingPayload = {
                event: 'pageState',
                pageState: {
                    pageState: 'hp-HeroBrandMessage',
                },
            };
            analytics.trackEDDL(payload);
        }, 1000);
    }

    private disableHomebasePageStyles() {
        document.body.classList.remove('is-return-visit');

        const headerEl = document.getElementById('header');
        if (headerEl) {
            headerEl.classList.remove('transparent-light');
            headerEl.classList.add('transparent-dark');
        }
    }

    public render(): React.ReactNode {
        this.disableHomebasePageStyles();

        return (
            <section
                id="first-time-visitor-hero"
                data-fs-section={`${this.props.fullStoryPageId}: Hero`}
                className="first-time-visitor-hero  mdc-layout-grid"
            >
                <div className="first-time-visitor-hero--max-width-container">
                    <picture>
                        <source media="(max-width: 767px)" srcSet="/home/images/home/hero/lycg-mobile.jpg" />
                        <source
                            media="(min-width: 767.1px) and (max-width: 1151px)"
                            srcSet="/home/images/home/hero/lycg-tablet.jpg"
                        />
                        <source media="(min-width: 1151.1px)" srcSet="/home/images/home/hero/lycg-xl.jpg" />
                        <img
                            className="first-time-visitor-hero-img"
                            src="/home/images/home/hero/lycg-xl.jpg"
                            alt="Parent and child sitting in the rear of their vehicle putting on skates"
                            width="4366"
                            height="1522"
                        />
                    </picture>
                    <div className="first-time-visitor-hero--grid-container mdc-layout-grid__inner">
                        <HeroFeaturedContent heroTheme={HeroTheme.Dark} ctaLabel="Search Cars" />
                    </div>
                </div>
            </section>
        );
    }
}
