import * as React from 'react';
import { HomebaseTab, HOMEBASE_CAROUSELS, LOADING_SPINNER_CLASS } from '../../../utilities/constants';
import { IVehicleTile } from '../../../../../types/vehicleTile';
import LoadingSpinner from '../../../../../components/loading-spinner';
import ReturnVisitorCarousel from '../../../../../components/carousels/home-base-hero-carousel/ReturnVisitorCarousel';
import { RecommendationsPlaceholderCarousel } from '../../../../../components/carousels/home-base-hero-carousel/RecommendationsPlaceholderCarousel';
import { FBSContext } from '../../../../../context';
import { trackEDDL } from '../../../../../utilities/analytics';
import { useEffect } from 'react';

interface IRecommendedCarouselProps {
    hasLoadedRecommendedTab: boolean;
    recommendedVehicleList: IVehicleTile[];
    isVisible: boolean;
    favoritedStockNumberList: number[];
    isLoadingRecommendedTab: boolean;
    stockNumbersPendingFavoritesUpdate: number[];
    onOpenSnackBar: (text: string) => any;
}

const RecommendedCarousel = (props: IRecommendedCarouselProps) => {
    const {
        hasLoadedRecommendedTab,
        recommendedVehicleList,
        isVisible,
        favoritedStockNumberList,
        isLoadingRecommendedTab,
        stockNumbersPendingFavoritesUpdate,
        onOpenSnackBar,
    } = props;

    const fbsContext = React.useContext(FBSContext);

    // Function to be called when a card's title is clicked
    const handleEddlOnClick = (card: Element, allStockNumbers: string[]) => {
        // Get vehicle's YMM
        const name = card.getAttribute('data-ymm') || '';
        const stockNumber = card.getAttribute('data-stocknumber');
        if (!stockNumber) return;

        // EDDL: Recommended Car - Clicked
        trackEDDL({
            event: 'recommendedCarClicked',
            linkDetails: {
                name,
                position: 'homepage home base hero tile recommended car',
            },
            recommendations: {
                stockNumber,
                similarVehicles: allStockNumbers.filter((sn) => sn !== stockNumber).join(','), // Filters out the stock number clicked
            },
            module: {
                moduleExperience: 'VR|Homepage|You might like',
            },
        });
    };

    useEffect(() => {
        if (!recommendedVehicleList.length) return;

        fbsContext.setVehicleList(recommendedVehicleList);

        // Get all the cards from the DOM
        const cards = document.querySelectorAll('#kmx-recommended-carousel .carousel__slide');

        // Get all stock numbers
        const allStockNumbers = Array.from(cards)
            .map((node) => node.getAttribute('data-stocknumber'))
            .filter((item) => item) as string[];

        // Loop through all cards to add event listeners and execute the EDDL function on page load
        cards.forEach((card) => {
            const stockNumber = card.getAttribute('data-stocknumber');
            if (!stockNumber) return;

            // EDDL: Recommended Car - Impression
            trackEDDL({
                event: 'recommededCarImpression',
                recommendations: {
                    stockNumber,
                    similarVehicles: allStockNumbers.filter((sn) => sn !== stockNumber).join(','),
                },
                module: {
                    moduleExperience: 'VR|Homepage|You might like',
                },
            });

            // Add event listeners
            card.addEventListener('click', () => handleEddlOnClick(card, allStockNumbers));
        });

        // Remove event listeners
        return () => {
            cards.forEach((card) => {
                card.removeEventListener('click', () => handleEddlOnClick(card, allStockNumbers));
            });
        };
    }, [recommendedVehicleList]);

    return hasLoadedRecommendedTab && recommendedVehicleList.length > 0 ? (
        <ReturnVisitorCarousel
            id="kmx-recommended-carousel"
            carouselName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].carouselName}
            analyticsName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].carouselAnalyticsName}
            isVisible={isVisible}
            vehicleTileList={recommendedVehicleList}
            favoritedStockNumberList={favoritedStockNumberList}
            stockNumbersPendingFavoritesUpdate={stockNumbersPendingFavoritesUpdate}
            ariaLabel={HOMEBASE_CAROUSELS.byId[HomebaseTab.Recommended].ariaLabel}
            enableRemove={false}
            fbsData={fbsContext.fbsData}
            onOpenSnackBar={onOpenSnackBar}
        />
    ) : isLoadingRecommendedTab ? (
        <LoadingSpinner className={LOADING_SPINNER_CLASS} />
    ) : hasLoadedRecommendedTab && recommendedVehicleList.length === 0 ? (
        <RecommendationsPlaceholderCarousel />
    ) : null;
};

export default RecommendedCarousel;
