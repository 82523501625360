import * as React from 'react';

interface IResearchArticlesTileProps {
    itemIndex: number;
    content: IResearchPrompt;
}

export enum ResearchTile {
    Prompt = 'PROMPT',
}

export interface IResearchPrompt {
    type: ResearchTile.Prompt;
    heading: string;
    buttonLabel: string;
}

class ResearchArticlesPrompt extends React.Component<IResearchArticlesTileProps> {
    constructor(props: any) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <a
                href="/research"
                className="research-articles--link"
                aria-label={this.props.content.heading + ' ' + this.props.content.buttonLabel}
                data-index={this.props.itemIndex}
            >
                <div className="research-articles--end-cap-prompt research-articles--tile">
                    <h3 className="research-articles--end-cap-prompt-heading">{this.props.content.heading}</h3>
                    <button className="research-articles--end-cap-prompt-button">
                        {this.props.content.buttonLabel}
                    </button>
                </div>
            </a>
        );
    }
}

export default ResearchArticlesPrompt;
