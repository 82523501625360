import { useEffect, useState } from 'react';
import { ILinkFarmTab, ILinkFarmTabLink } from '../../../api/types/content-api';
import { smoothScroll } from '../../../utilities/smoothScroll';
import { trackEDDL } from '../../../utilities/analytics';

const LinkFarmTabContent = (props: any) => {
    const { tabName, tabLinks }: ILinkFarmTab = props.tab;
    const { data, activeTab, tabIndex, linkFarmRef } = props;
    const moreButtonThreshold = 20;

    const [tabsOpenStatus, setTabsOpenStatus] = useState(() => {
        // creates { 0: true, 1:false, 2: false, ... }
        const tabStatus: { [key: string]: boolean } = {};
        data.tabItems.forEach((tab: ILinkFarmTab, i: number) => {
            tabStatus[String(i)] = false;
        });
        return tabStatus;
    });

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(activeTab === tabIndex);
    }, [activeTab]);

    function handleToggleTabContent(i: number) {
        setTabsOpenStatus({ [i]: !tabsOpenStatus[i] });
        const tabWillBeClosed = !tabsOpenStatus[i] === false;
        tabWillBeClosed && smoothScroll((linkFarmRef?.current as unknown) as HTMLElement, -20);
    }

    function renderLinks(tabName: string) {
        return tabLinks.map((link: ILinkFarmTabLink, i: number) => {
            const { linkUrl, linkText, linkAttribute, linkAttributeValue } = link;
            const customAttribute = { [linkAttribute as string]: linkAttributeValue };
            const handleOnClick = () => {
                // Analytics
                trackEDDL({
                    event: 'ctaClick',
                    linkDetails: {
                        name: linkText,
                        position: `Browse By: ${tabName}`,
                    },
                });

                // Redirect
                window.location.href = linkUrl;
            };
            return (
                <li key={i}>
                    <button onClick={handleOnClick} {...customAttribute}>
                        {linkText}
                    </button>
                </li>
            );
        });
    }

    return (
        <div className="hp-tabs-contents">
            <div
                id={`link-farm-tabs--tab-content__${tabName}`}
                className={`hp-tabs-content link-farm-tabs--tab-content
                ${
                    tabsOpenStatus[activeTab] ? 'link-farm-tabs--tab-content__is-expanded' : ''
                }  link-farm-tabs--tab-content${isActive ? '--active' : ''}`}
            >
                <div className="link-farm-tabs--link-list-expansion-container">
                    <a className="skip-link" href="#mobile-apps">
                        Skip List of Popular Vehicles
                    </a>
                    <ul className="link-farm-tabs--link-list">{renderLinks(tabName)}</ul>
                </div>
                {tabLinks.length > moreButtonThreshold && (
                    <div className="link-farm-tabs--view-more-container" aria-hidden="true">
                        <button
                            className="link-farm-tabs--view-more-button"
                            data-for={`link-farm-tabs--tab-content__${tabName}`}
                            onClick={() => {
                                // Analytics
                                trackEDDL({
                                    event: 'ctaClick',
                                    linkDetails: {
                                        name: `View ${tabsOpenStatus[tabIndex] ? 'less' : 'more'}`,
                                        position: 'Browse By',
                                    },
                                });
                                // Toggle content
                                handleToggleTabContent(tabIndex);
                            }}
                        >
                            <span className="link-farm-tabs--view-more-button-label">
                                View {tabsOpenStatus[tabIndex] ? 'less' : 'more'}
                            </span>
                            <span className="link-farm-tabs--view-more-button-icon">
                                <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                >
                                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                                    <path d="M0-.75h24v24H0z" fill="none"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LinkFarmTabContent;
