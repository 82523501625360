import * as React from 'react';
import { HeroTheme } from '../utilities/constants';

interface IHeroFeaturedContentProps {
    heroTheme: HeroTheme.Light | HeroTheme.Dark;
    ctaLabel: string;
}

interface IHeroFeaturedContentState {
    dialogOpen: boolean;
}

export default class HeroFeaturedContent extends React.Component<IHeroFeaturedContentProps, IHeroFeaturedContentState> {
    constructor(props: IHeroFeaturedContentProps) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <div
                className={`hero-featured-content hero-featured-content__${this.props.heroTheme} mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop`}
            >
                <div className="hero-featured-content--content-container">
                    <h1 className="hero-featured-content--heading">
                        <span className="hero-featured-content--heading-top">
                            <span>The way car&nbsp;</span>
                        </span>
                        <span className="hero-featured-content--heading-bottom">
                            <span>buying should be</span>
                        </span>
                    </h1>
                    <div className="hero-featured-content--subheading">
                        <span className="hero-featured-content--subheading-copy">
                            Upfront prices. CarMax Certified quality.
                        </span>
                    </div>
                    <hzn-button href="/cars/all" class="hero-featured-content--cta">
                        {this.props.ctaLabel}
                    </hzn-button>
                </div>
            </div>
        );
    }
}
