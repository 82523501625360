import * as React from 'react';
import { gsap, TweenMax, TimelineMax } from 'gsap';
import { IRecentSearchTile } from '../../utilities/types';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { fetchAndBuildRecommendedVehicleTiles } from '../../utilities/recommendationsBuilder';
import {
    HomebaseTab,
    HOMEBASE_CAROUSELS,
    TAB_LIST_ARIA_LABEL,
    MOBILE_TABLET_BREAKPOINT,
} from '../../utilities/constants';
import * as analytics from '../../../../utilities/analytics';
import classNames from 'classnames';
import RecommendedCarousel from './carousels/RecommendedCarousel';
import RecentlyViewedCarousel from './carousels/RecentlyViewedCarousel';
import SearchedCarousel from './carousels/SearchedCarousel';
import { ServerSideExperimentContext } from '../../campaigns/ServerSideExperimentContext';
import { removeViewedVehicleItemLocalStorage } from '../../../../pages/home/utilities/viewedVehiclesTileBuilder';
import { getRecentSearches } from '../../../../api/recent-search-api';
import { ITrackingPayload } from '../../../../types/analytics';

interface IHomeBaseTabsProps {
    viewedVehicleList: IVehicleTile[];
    favoritedVehicleList: IVehicleTile[];
    favoritedStockNumberList: number[];
    stockNumbersPendingFavoritesUpdate: number[];
    onOpenSnackBar: (text: string) => void;
}

interface IHomeBaseTabsState {
    activeTabIndex: number;
    activeTabRef: any;
    defaultTabRef: any;
    tabListOrder: number[];
    recommendedVehicleList: IVehicleTile[];
    viewedList: IVehicleTile[];
    isLoadingRecommendedTab: boolean;
    hasLoadedRecommendedTab: boolean;
    recentSearchesList: IRecentSearchTile[];
    isLoadingSearchedTab: boolean;
    hasLoadedSearchedTab: boolean;
    persistedFavoritedStockNumberList: number[];
    persistedFavoritedVehicleList: IVehicleTile[];
    pillCurrentlyTransitioning: boolean;
}

export default class HomeBaseTabs extends React.Component<IHomeBaseTabsProps, IHomeBaseTabsState> {
    tabButtonRefs: any;
    animatedActiveStateIndicator: any;
    pillTransition: any; // gsap timeline
    static contextType?: React.Context<any> | undefined = ServerSideExperimentContext;
    context!: React.ContextType<typeof ServerSideExperimentContext>;

    constructor(props: IHomeBaseTabsProps) {
        super(props);

        this.tabButtonRefs = {
            [HomebaseTab.RecentlyViewed]: React.createRef(),
            [HomebaseTab.Recommended]: React.createRef(),
            [HomebaseTab.Searched]: React.createRef(),
        };

        this.animatedActiveStateIndicator = React.createRef();

        this.state = {
            activeTabIndex: 0,
            activeTabRef: this.tabButtonRefs[HOMEBASE_CAROUSELS.defaultCarouselOrder[0]],
            defaultTabRef: this.tabButtonRefs[HOMEBASE_CAROUSELS.defaultCarouselOrder[0]],
            tabListOrder: HOMEBASE_CAROUSELS.defaultCarouselOrder,
            recommendedVehicleList: [],
            viewedList: this.props.viewedVehicleList,
            isLoadingRecommendedTab: false,
            hasLoadedRecommendedTab: false,
            recentSearchesList: [],
            isLoadingSearchedTab: false,
            hasLoadedSearchedTab: false,
            persistedFavoritedStockNumberList: this.props.favoritedStockNumberList,
            persistedFavoritedVehicleList: this.props.favoritedVehicleList,
            pillCurrentlyTransitioning: false,
        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.loadRecommendationsTab = this.loadRecommendationsTab.bind(this);
        this.loadSearchedTab = this.loadSearchedTab.bind(this);
        this.updateViewedTiles = this.updateViewedTiles.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    private initActiveStateIndicator() {
        const refPositionInfo = this.state.defaultTabRef.current.getBoundingClientRect();

        // set initial dimensions and position
        gsap.set(this.animatedActiveStateIndicator.current, {
            width: refPositionInfo.width,
            x: refPositionInfo.x,
            display: 'none',
        });
    }

    private transitionPillActiveState(ref: any, nextTabId: number) {
        const newTabPositionInfo = ref.current.getBoundingClientRect();
        const currentTabPositionInfo = this.state.activeTabRef.current.getBoundingClientRect();
        const activeStateIndicator = this.animatedActiveStateIndicator.current;

        if (window.innerWidth < MOBILE_TABLET_BREAKPOINT) {
            this.pillTransition = new TimelineMax({
                onStart: () => {
                    this.setState({
                        pillCurrentlyTransitioning: true,
                    });
                },
                onComplete: () => {
                    this.setState(
                        {
                            pillCurrentlyTransitioning: false,
                        },
                        () => {
                            TweenMax.set(activeStateIndicator, { display: 'none' });
                        }
                    );
                },
            });

            // set dimensions and location of CURRENT active tab (start position of animation)
            this.pillTransition.set(activeStateIndicator, {
                width: currentTabPositionInfo.width,
                x: currentTabPositionInfo.x,
                display: 'block',
            });

            if (this.state.tabListOrder.indexOf(nextTabId) > this.state.activeTabIndex) {
                // moving from left to right

                // start transition of width
                this.pillTransition.to(activeStateIndicator, 0.25, {
                    width: currentTabPositionInfo.width + newTabPositionInfo.width,
                    ease: 'power2.inOut',
                });

                // catch up x position during width transition (drag effect)
                this.pillTransition.to(
                    activeStateIndicator,
                    0.25,
                    {
                        width: newTabPositionInfo.width,
                        x: newTabPositionInfo.x,
                        ease: 'power2.inOut',
                    },
                    '-=.125'
                );
            } else {
                // moving from right to left

                // start transition of width and x value
                this.pillTransition.to(activeStateIndicator, 0.25, {
                    width: currentTabPositionInfo.width + newTabPositionInfo.width,
                    x: newTabPositionInfo.x,
                    ease: 'power2.inOut',
                });

                // reduce width
                this.pillTransition.to(
                    activeStateIndicator,
                    0.25,
                    {
                        width: newTabPositionInfo.width,
                        ease: 'power2.inOut',
                    },
                    '-=.125'
                );
            }

            // add timing buffer to end of timeline (dummy tween)
            this.pillTransition.set({}, {}, 0.5);
        }
    }

    private handleKeyPress(currentTabId: number, event: React.KeyboardEvent<HTMLButtonElement>) {
        let currentTabIndex = this.state.tabListOrder.indexOf(currentTabId);
        let nextTabIndex = -1;
        const atStartOfTabList = currentTabIndex === 0;
        const atEndOfTabList = currentTabIndex === this.state.tabListOrder.length - 1;

        switch (event.key) {
            case 'ArrowRight':
                nextTabIndex = atEndOfTabList ? 0 : ++currentTabIndex;
                break;
            case 'ArrowLeft':
                nextTabIndex = atStartOfTabList ? this.state.tabListOrder.length - 1 : --currentTabIndex;
                break;
            default:
        }

        if (nextTabIndex !== -1) {
            const nextTabId = this.state.tabListOrder[nextTabIndex];
            const nextTabRef = this.tabButtonRefs[nextTabId];

            this.handleTabChange(nextTabId, nextTabRef);
            nextTabRef.current.focus();
        }
    }

    private handleTabChange(nextTabId: number, nextTabRef: any) {
        const newState: any = {
            activeTabIndex: this.state.tabListOrder.indexOf(nextTabId),
            activeTabRef: nextTabRef,
        };

        this.transitionPillActiveState(nextTabRef, nextTabId);

        this.setState(newState, () => {
            if (nextTabId === HomebaseTab.Searched && !this.state.hasLoadedSearchedTab) {
                this.loadSearchedTab();
            } else if (nextTabId === HomebaseTab.Recommended && !this.state.hasLoadedRecommendedTab) {
                this.loadRecommendationsTab();
            }
        });
    }

    private loadRecommendationsTab() {
        this.setState({ isLoadingRecommendedTab: true }, () => {
            fetchAndBuildRecommendedVehicleTiles(
                (recommendedVehicleTileList: IVehicleTile[]) => {
                    this.setState({
                        isLoadingRecommendedTab: false,
                        hasLoadedRecommendedTab: true,
                        recommendedVehicleList: recommendedVehicleTileList,
                    });
                },
                this.props.favoritedStockNumberList,
                this.props.viewedVehicleList
            );
        });
    }

    private loadSearchedTab() {
        this.setState({ isLoadingSearchedTab: true }, async () => {
            const recentSearchTileList = await getRecentSearches();
            const newState: any = {
                isLoadingSearchedTab: false,
                hasLoadedSearchedTab: true,
            };
            if (recentSearchTileList.length > 0) {
                newState.recentSearchesList = recentSearchTileList;
            }
            this.setState(newState);
        });
    }

    private updateViewedTiles(tileToRemove: IVehicleTile) {
        const newViewedTileList: IVehicleTile[] = [];
        this.state.viewedList.forEach((tileData) => {
            if (tileData.stockNumber === tileToRemove.stockNumber) {
                return;
            } else {
                newViewedTileList.push(tileData);
            }
        });

        removeViewedVehicleItemLocalStorage(tileToRemove);
        this.setState({ viewedList: newViewedTileList }, () => {
            this.props.onOpenSnackBar('Removed from your recently viewed');
        });
    }

    public componentDidMount(): void {
        setTimeout(function () {
            analytics.track(
                HOMEBASE_CAROUSELS.byId[HOMEBASE_CAROUSELS.defaultCarouselOrder[0]].initiationAnalyticsLabel,
                true
            );
            const pageInfo = window.digitalData.page.pageInfo;
            const userInfo = window.digitalData.user[0].attributes;
            const pageLoadPayLoad: ITrackingPayload = {
                event: 'pageLoad',
                pageInfo: {
                    pageName: pageInfo.pageName,
                    pageID: pageInfo.pageID,
                    originPage: pageInfo.originPage,
                    referringURL: pageInfo.referringURL,
                    subsection: pageInfo.subsection,
                    channel: pageInfo.channel,
                },
                userInfo: {
                    akamaiCity: userInfo.akamaiCity,
                    akamaiLatitude: userInfo.akamaiLatitude,
                    akamaiLongitude: userInfo.akamaiLongitude,
                    akamaiZip: userInfo.akamaiZip,
                    akamiRegionCode: userInfo.akamiRegionCode,
                    akamiTimeZone: userInfo.akamiTimeZone,
                    distanceToStore: userInfo.distanceToStore,
                    isFirstTimeVisitor: userInfo.isFirstTimeVisitor,
                    nearestStoreId: userInfo.nearestStoreId,
                    nearestStoreName: userInfo.nearestStoreName,
                    nearestStoreZip: userInfo.nearestStoreZip,
                    userZip: userInfo.userZip,
                    usingStoreProxy: userInfo.usingStoreProxy,
                    visitorID: userInfo.visitorID,
                    testSegment: {
                        logOdds: userInfo.testSegment.logOdds,
                        logOddsA: userInfo.testSegment.logOddsA,
                        logOddsI: userInfo.testSegment.logOddsI,
                        modelDecile: userInfo.testSegment.modelDecile,
                    },
                },
                profile: {
                    profileID: window.digitalData.user[0].profile[0].profileID,
                },
            };
            analytics.trackEDDL(pageLoadPayLoad);
            const payload: ITrackingPayload = {
                event: 'pageState',
                pageState: {
                    pageState: 'hp-HeroHomebaseMVP-Viewed',
                },
            };
            analytics.trackEDDL(payload);
        }, 1000);

        this.initActiveStateIndicator();
    }

    public componentDidUpdate(prevProps: IHomeBaseTabsProps, prevState: IHomeBaseTabsState): void {
        if (
            this.props.favoritedStockNumberList.length &&
            prevState.persistedFavoritedStockNumberList !== this.state.persistedFavoritedStockNumberList
        ) {
            this.setState({
                persistedFavoritedStockNumberList: prevProps.favoritedStockNumberList,
            });
        }
        if (
            this.props.favoritedVehicleList.length &&
            prevState.persistedFavoritedVehicleList !== this.state.persistedFavoritedVehicleList
        ) {
            this.setState({
                persistedFavoritedVehicleList: prevProps.favoritedVehicleList,
            });
        }
    }

    private renderTabList(): React.ReactNode {
        const tabButtons: React.ReactElement[] = [];

        this.state.tabListOrder.forEach((tabId: number, index) => {
            const tabRef = this.tabButtonRefs[tabId],
                label = HOMEBASE_CAROUSELS.byId[tabId].label,
                dataAnalytics = HOMEBASE_CAROUSELS.byId[tabId].dataAnalyticsAttr,
                icon = HOMEBASE_CAROUSELS.byId[tabId].tabButtonIcon,
                isActiveTab = this.state.activeTabIndex === this.state.tabListOrder.indexOf(tabId),
                tabButtonId = HOMEBASE_CAROUSELS.byId[tabId].tabButtonId,
                tabPanelId = HOMEBASE_CAROUSELS.byId[tabId].tabPanelElementId,
                ariaLabel = HOMEBASE_CAROUSELS.byId[tabId].ariaLabel;

            tabButtons.push(
                <button
                    key={index}
                    ref={tabRef}
                    className={classNames('mdc-tab mdc-tab--min-width', {
                        'mdc-tab--active': isActiveTab,
                    })}
                    role="tab"
                    tabIndex={isActiveTab ? 0 : -1}
                    aria-label={ariaLabel}
                    aria-selected={isActiveTab ? 'true' : 'false'}
                    aria-controls={tabPanelId}
                    id={tabButtonId}
                    onClick={() => {
                        this.handleTabChange(tabId, tabRef);
                    }}
                    onKeyDown={(keyboardEvent) => {
                        this.handleKeyPress(tabId, keyboardEvent);
                    }}
                    data-kmx-analytics={dataAnalytics}
                >
                    <span className="mdc-tab__content">
                        <span className="mdc-tab__text-label">
                            <span className="home-base-hero--personalized-tabs-icon">{icon}</span>
                            {label}
                        </span>
                    </span>
                    <span
                        className={classNames('mdc-tab-indicator', {
                            'mdc-tab-indicator--active': isActiveTab,
                        })}
                    />
                </button>
            );
        });

        return (
            <div className="home-base-hero--personalized-tabs-container">
                <div className="hp-tab-bar" role="tablist" aria-label={TAB_LIST_ARIA_LABEL}>
                    <div
                        ref={this.animatedActiveStateIndicator}
                        className="home-base-hero--personalized-tabs-active-state"
                    />
                    {tabButtons.map((tab) => tab)}
                </div>
            </div>
        );
    }

    private renderTabPanels(): React.ReactNode {
        const tabPanels: {
            allIds: number[];
            byId: {
                [key: number]: { render: () => any };
            };
        } = {
            allIds: [HomebaseTab.RecentlyViewed, HomebaseTab.Searched, HomebaseTab.Recommended],
            byId: {
                [HomebaseTab.RecentlyViewed]: {
                    render: () => (
                        <RecentlyViewedCarousel
                            onOpenSnackBar={this.props.onOpenSnackBar}
                            isVisible={
                                this.state.activeTabIndex ===
                                this.state.tabListOrder.indexOf(HomebaseTab.RecentlyViewed)
                            }
                            viewedVehicleList={[...this.state.viewedList, ...this.state.persistedFavoritedVehicleList]}
                            stockNumbersPendingFavoritesUpdate={this.props.stockNumbersPendingFavoritesUpdate}
                            updatedViewedTiles={this.updateViewedTiles}
                        />
                    ),
                },
                [HomebaseTab.Searched]: {
                    render: () => (
                        <SearchedCarousel
                            isVisible={
                                this.state.activeTabIndex === this.state.tabListOrder.indexOf(HomebaseTab.Searched)
                            }
                            recentSearchesList={this.state.recentSearchesList}
                            isLoadingSearchedTab={this.state.isLoadingSearchedTab}
                            hasLoadedSearchedTab={this.state.hasLoadedSearchedTab}
                            leftAligned={false}
                        />
                    ),
                },
                [HomebaseTab.Recommended]: {
                    render: () => (
                        <RecommendedCarousel
                            hasLoadedRecommendedTab={this.state.hasLoadedRecommendedTab}
                            recommendedVehicleList={this.state.recommendedVehicleList}
                            isVisible={
                                this.state.activeTabIndex === this.state.tabListOrder.indexOf(HomebaseTab.Recommended)
                            }
                            favoritedStockNumberList={this.props.favoritedStockNumberList}
                            isLoadingRecommendedTab={this.state.isLoadingRecommendedTab}
                            stockNumbersPendingFavoritesUpdate={this.props.stockNumbersPendingFavoritesUpdate}
                            onOpenSnackBar={this.props.onOpenSnackBar}
                        />
                    ),
                },
            },
        };

        return tabPanels.allIds.map((panelId) => (
            <div
                key={panelId}
                className={classNames('hp-tab-content', {
                    active: this.state.activeTabIndex === this.state.tabListOrder.indexOf(panelId),
                    hidden: !(this.state.activeTabIndex === this.state.tabListOrder.indexOf(panelId)),
                })}
                id={HOMEBASE_CAROUSELS.byId[panelId].tabPanelElementId}
                role="tabpanel"
                aria-labelledby={HOMEBASE_CAROUSELS.byId[panelId].tabButtonId}
                aria-label={HOMEBASE_CAROUSELS.byId[panelId].ariaLabel}
            >
                {tabPanels.byId[panelId].render()}
            </div>
        ));
    }

    public render(): React.ReactNode {
        return (
            <div className="home-base-hero--main-content home-base-hero--personalized-tabs">
                {this.renderTabList()}
                {this.renderTabPanels()}
            </div>
        );
    }
}
